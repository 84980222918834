.clock {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  > svg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
    
    > img:first-child {
      position: absolute;
      width: 70%;
      z-index: 1;
    }
    
    > img:nth-child(2) {
      position: absolute;
      width: 57%;
    }

    > img:nth-child(3) {
      width: 100%;
      z-index: 1;
    }

  > .circle {
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
  }
}