@import '../../styles/colors.scss';

@keyframes stretch-button {
  0% {
    width: 50px;
  }
  100% {
    width: 80%;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .login {
    img {
      padding: 10px;
    }

    .login-btn {
      background-color: white;
      width: 80%;
      height: 50px;
      border: 0.1em solid $cta-color;
      color: $cta-color;
      padding: 10px;
      text-transform: uppercase;
      font-size: 18px;
      letter-spacing: 1.5px;
      border-radius: 25px;
      font-weight: 500;
      animation: stretch-button 0.6s ease-in-out;
      > span {
        opacity: 0;
        animation: fade-in 0.8s;
        animation-delay: 0.2s;
        animation-fill-mode: both;
      }
    }
    position: absolute;
    top: 15%;
    width: 50%;
    min-width: 200px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
