.root {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  top: 0.4rem;
  right: 5px;
}

.people {
  font-size: 0.8rem;
  font-weight: 600;
  opacity: 0.3;
}
