@import '../../styles/colors.scss';

.root {
  border-radius: 8px;
  background-color: $color-dark-blue;
  padding: 10px;
  box-shadow: border-box;
  position: relative;
}

.title {
  color: white;
  margin: 10px 0;
  font-size: 16px;
}

.text {
  color: white;
  font-size: 14px;
  width: 75%;
}

.btnWrapper {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
