.switch {
  font-size: 25px;
}

.root {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  background-color: black;
  opacity: 0.7;
  z-index: 4;
  position: fixed;
}

.modal {
  position: fixed;
  top: 100px;
  left: 30px;
  z-index: 10000;
  height: calc(100vh - 300px);
  width: calc(100vw - 60px);
  background-color: white;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 20px;
}

.title {
  margin: 0;
}

.buttonWrapper {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.defaultTime {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
