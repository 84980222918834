.handbook-card {
  max-width: 203px;
  height: 354px;
  box-shadow: 3px 3px 10px rgba(30, 30, 30, 0.1);
  position: relative;
  border-radius: 20px;
  border-top-left-radius: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  img {
    width: 60%;
  }

  @media only screen and (max-width: 450px) {
    max-width: 150px;
    height: 150px;
    justify-content: space-around;

    .description {
      display: none;
    }

    > h2 {
      margin: 0;
      text-align: center;
      position: relative;
      z-index: 1;
      font-size: 16px;
    }
  }
}
