.link {
  text-decoration: none !important;
  position: relative;
}

.title {
  margin: 0 -20px;
  top: 5px;
  left: 30px;
  text-align: center;
  position: absolute;
  z-index: 1;
  font-size: 14px;
  color: black;
}

.room {
  width: 110px;
  height: 250px;
  box-shadow: 3px 3px 10px rgba(30, 30, 30, 0.1);
  position: relative;
  border-radius: 20px;
  border-top-left-radius: 0px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 10px 0px;

  &:hover {
    cursor: pointer;
  }

  .statusCorner {
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    transform: rotate(90deg);
    #corner {
      transition: fill 0.5s ease;
    }
  }

  .clock {
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    > img {
      display: flex;
      justify-content: center;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
    }

    > .circle {
      filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
    }
  }

  > .color-filter {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: aquamarine;
    opacity: 0.5;
  }

  > img {
    width: 100%;
  }

  @media only screen and (max-width: 450px) {
    max-width: 150px;
    height: 160px;
    justify-content: flex-start;

    > .clock {
      height: 50%;
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;

      > img {
        margin-top: 25px;
        display: flex;
        justify-content: center;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;

        &:first-child {
          width: 80%;
        }

        &:nth-child(2) {
          width: 80%;
        }

        &:nth-child(3) {
          width: 120%;
        }
      }
    }

    // > h2 {
    //   margin: 0 -20px;
    //   text-align: center;
    //   position: relative;
    //   z-index: 1;
    //   font-size: 16px;
    //   color: black;
    // }
  }
}

.freeText {
  margin-top: 40px;
  text-align: center;
  color: green;
  font-weight: 600;
  font-size: 12px;
  font-style: italic;
}
.busyText {
  margin-top: 40px;
  text-align: center;
  color: red;
  font-weight: 600;
  font-size: 12px;
  font-style: italic;
}
