.tiny {
  width: 5px;
  height: 5px;
}
.small {
  width: 10px;
  height: 10px;
}
.medium {
  width: 15px;
  height: 15px;
}
.large {
  width: 30px;
  height: 30px;
}
.xlarge {
  width: 50px;
  height: 50px;
}
