@import '../../styles/colors.scss';

.eventInfo {
  position: relative;
  border: 3px solid $color-dark-blue;
  padding: 10px 20px;
  border-radius: 10px;
  width: 100%;
  transition: all 1s ease;
  box-sizing: border-box;
}

.subjectText {
  margin: 5px 0;
}

.timeText {
  margin: 0;
  font-size: 12px;
  color: $color-disable-dark-grey;
}

.deleteButton {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.close {
  position: absolute;
  top: 0;
  right: 10px;
}
