@import '../../styles/colors.scss';

.roomGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
}

.home {
  display: flex;
  flex-direction: column;
  padding: 0 15px;

  .rooms {
    position: relative;
    overflow-y: scroll;
    scroll-snap-type: x mandatory;
    overflow-x: auto;
    display: flex;
    z-index: 1;
    padding-right: 10px;
    > *:nth-child(n) {
      margin-right: 20px;
      scroll-behavior: smooth;
      scroll-snap-align: center;
    }
    > :first-child {
      margin-left: 120px;
    }
    > :last-child {
      margin-right: 120px;
    }
    padding-bottom: 10px;
  }

  .apps {
    padding: 0 20px;
    display: flex;
    flex-wrap: wrap;

    > h2 {
      display: flex;
      width: 100%;
      margin-top: 0;
      margin-bottom: 20px;
    }

    > a {
      @media only screen and (max-width: 450px) {
        width: calc(50% - 20px);
      }
      &:nth-child(2n) {
        margin-right: 20px;
      }
      margin-bottom: 20px;
      text-decoration: none;
      color: black;
    }

    & :last-child {
      margin-right: 0px;
    }
  }

  > footer {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
  }
}

.title {
  margin: 10px 0;
  font-size: 18px;
}

.roomLocationButton {
  background: none;
  border: none;
  font-weight: 600;
  color: $color-dark-blue;
}

.horizontalScroll {
  position: relative;
  width: 100vw;
  overflow-y: scroll;
  display: flex;
  z-index: 1;
}

.root {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  background-color: black;
  opacity: 0.7;
  z-index: 4;
  position: fixed;
}

.modal {
  position: fixed;
  top: 100px;
  left: 30px;
  z-index: 10000;
  height: calc(100vh - 300px);
  width: calc(100vw - 60px);
  background-color: white;
  border-radius: 20px;
  box-sizing: border-box;
  padding: 20px;
}

.title {
  margin: 0;
}

.buttonWrapper {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.switcher {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
