.room-details-info-text {
  text-align: center;
  margin-top: 0;
  font-style: italic;
  font-size: 14px;
}

.room-details {
  max-height: 100vh;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin: 10px;
  }

  .clock {
    width: 100%;
    max-width: 400px;
  }

  form {
    max-width: 250px;
    display: flex;
    flex-direction: column;

    > * {
      border-radius: 5px;
      font-size: 20px;
      margin: 10px 0;
    }

    select,
    input {
      box-sizing: border-box;
      width: 100%;
      color: black;
      border: 2px solid #a0ddf7;
      text-align-last: center;
      padding: 5px;
      background: transparent;
    }

    > p {
      margin: 2.5px 10px;

      > img {
        display: inline;
        height: 20px;
        margin-right: 5px;
        margin-bottom: -2px;
      }

      > button {
        width: 15px;
        height: 15px;
        padding: 0px;
        padding-bottom: 2px;
        border-radius: 7.5px;
        margin-left: 10px;
        vertical-align: middle;
      }
    }

    ul {
      width: 100%;
      list-style: none;
      text-align: left;
      margin-top: -10px;
      padding: 0;
      border: 2px solid #a0ddf7;
      border-top: none;
      box-sizing: border-box;
      > li {
        &:nth-child(odd) {
          background-color: #a0ddf744;
          padding: 2px;
        }

        &:hover {
          cursor: pointer;
          background-color: #a0ddf7;
        }
      }
    }

    button {
      width: 100%;
      color: white;
      border: none;
      background-color: #e24585;
      padding: 10px;

      &:disabled {
        background-color: #d1d2d1;
      }
    }
  }
}
