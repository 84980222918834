@import '../../styles/colors.scss';

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  padding-right: 25px;
  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    border-radius: 50%;
    border-top: 2px solid white;
    border-right: 2px solid transparent;
    animation: spinner 0.4s linear infinite;
  }
}

.disabled {
  opacity: 0.5;
}

.button {
  position: relative;
  border: none;
  color: white;
  font-weight: bold;
}

.filledButton {
  background-color: $color-green;
}

.deleteButton {
  background-color: $color-red;
}

.small {
  height: 30px;
  padding: 5px 10px;
  font-size: 10px;
  border-radius: 5px;
  &:disabled {
    padding-right: 25px;
  }
  &:before {
    right: 10%;
    width: 10px;
    height: 10px;
    margin-top: -5px;
    margin-left: -5px;
  }
}

.medium {
  height: 40px;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  &:before {
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-top: 5px solid $cta-color;
  }
}
